import react from 'react';


const AddLeadPopup = (props)=>{
    
    return(
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>
                    <div className='add-new-head'>
                         <h2> Add New Lead <i className='fa fa-user-plus'></i> </h2> 
                            <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form>
                            <label> Lead Generation Date* </label>
                            <br/>
                            <input type='date' className='form-input' required/>

                            <label> Name* </label>
                            <br/>
                            <input type='text' className='form-input' placeholder='name' required/>

                            <label> Phone* </label>
                            <br/>
                            <input type='number' className='form-input' placeholder='phone' required/>

                            <label> Email* </label>
                            <br/>
                            <input type='email' className='form-input' placeholder='email' required/>

                            <select required>
                                <option value=""> Ad Name </option>
                                <option value=""> Tronica City </option>
                                <option value=""> Bhiwadi </option>
                            </select>


                            <select required>
                                <option value=""> Plateform </option>
                                <option value=""> FB </option>
                                <option value=""> IG </option>
                                <option value=""> Call </option>
                                <option value=""> Google </option>
                                <option value=""> WhatsApp </option>
                            </select>


                            <select required>
                                <option value=""> Site </option>
                                <option value=""> Tronica City </option>
                                <option value=""> Bhiwadi </option>
                            </select>


                            <label> Message </label>
                            <br/>
                            <textarea name="message" placeholder='message (optional)'></textarea>

                            <button type='submit' className='crm-btn-primary'> Add Lead </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddLeadPopup;