import react from 'react';


const EditLeadDetailsPopup = (props)=>{
    
    return(
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>
                    <div className='add-new-head'>
                         <h2> Edit Details <i className='fa fa-address-card'></i> </h2> 
                            <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form>
                            
                            <label> Location* </label>
                            <br/>
                            <input type='text' className='form-input' placeholder='location' required/>

                            <select required>
                                <option value=""> Site </option>
                                <option value=""> Anmol Vihar </option>
                                <option value=""> Supreme Field Bhiwadi </option>
                            </select>

                            <select required>
                                <option value=""> Client Category </option>
                                <option value=""> Investor </option>
                                <option value=""> Residential </option>
                            </select>                        

                            <select required>
                                <option value=""> Property Type </option>
                                <option value=""> Plot </option>
                                <option value=""> Flat </option>
                            </select>                           


                            <label> Budget* </label>
                            <br/>
                            <input type='number' className='form-input' placeholder='phone' required/>

                            <label> Message </label>
                            <br/>
                            <textarea name="message" placeholder='message (optional)'></textarea>

                            <button type='submit' className='crm-btn-primary'> Submit </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditLeadDetailsPopup;