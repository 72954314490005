import react from 'react';


const AddResponsePopup = (props) => {

    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>
                    <div className='add-new-head'>
                        <h2> Add Response <i className='fa fa-reply'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form>

                            <label> Calling Date/Time* </label>
                            <br />
                            <input type='date' className='form-input' required />
                            <input type='time' className='form-input' required />

                            <select required>
                                <option value=""> Call By (Phone No) </option>
                                <option value=""> +91 9999999999 </option>
                                <option value=""> +91 7777777777 </option>
                            </select>


                            <div className='response-tags'>
                                <div className='tag'> NP </div>
                                <div className='tag'> NI </div>
                                <div className='tag'> Switch Off </div>
                                <div className='tag'> Invalid No. </div>
                                <div className='tag'> Not Reachable </div>
                                <div className='tag'> Wrong No </div>
                                <div className='tag'> Busy </div>
                                <div className='tag'> Call Cut </div>
                                <div className='tag'> Out Of Network </div>
                                <div className='tag'> Speaking to Someone Else </div>
                                <div className='tag crm-bg-green crm-color-white'> Ready for Visit </div>
                                <div className='tag crm-bg-primary crm-color-white'> Talked </div>
                            </div>
                           
                         
                            <label> Message </label>
                            <br />
                            <textarea name="message" placeholder='message' required></textarea>

                            <label> Next Follow Up Date/Time* </label>
                            <br />
                            <input type='date' className='form-input' required />
                            <input type='time' className='form-input' required />

                            <button type='submit' className='crm-btn-primary'> Submit </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddResponsePopup;