import React,{useState,useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Header from './components/Header';

const PendingFollowUps = ()=>{
  
    const navigate = useNavigate();

    useEffect(()=>{
        if(localStorage.getItem("user")){
            const userData = JSON.parse(localStorage.getItem("user"));
            if(userData.designation == 1 ){
                // navigate("/");
            }
        }
    },[]);
    
    return(
        <>

        <Header/>

        <div className='container-head'>
                <div>
                    <h2> Pending Follow Ups</h2>
                </div>

            </div>

        <div className='crm-container'>
            <div className='crm-table visible-hide'>

                <table>
                    <thead>
                        <tr> <th> S No</th> <th> Date </th> <th> Name </th> <th> Phone </th> <th> Email </th> <th> Action </th></tr>
                    </thead>

                    <tbody>
                        <tr><td>1</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to='/view-lead-details'>View </Link> </td></tr>

                        <tr><td>2</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to=''>View </Link> </td></tr>

                        <tr><td>3</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to=''>View </Link> </td></tr>

                        <tr><td>4</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to=''>View </Link> </td></tr>

                        <tr><td>5</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to=''>View </Link> </td></tr>

                        <tr><td>6</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to=''>View </Link> </td></tr>

                        <tr><td>7</td> <td>21-09-2024</td> <td>Jatin</td> <td> 9999999999 </td> <td>jatin@gmail.com</td>  <td> <Link to=''>View </Link> </td></tr>
                        

                        
                    </tbody>
                </table>
            </div>
            

            <div className='crm-phone-table hide-visible'>
                <div className='phone-table-item'>
                <div className='table-item-left'>
                        <h2> 1 </h2>
                </div>
                <div className='table-item-right'>
                    <div> 
                        <h2>Jatin </h2> 
                        <h2>+91 9999999999 </h2>   
                    </div>
                    <div>
                        <p> jatin@gmail.com </p>
                    </div>
                    <div>
                        <p> 21-09-2024 </p>
                        <Link to='/view-lead-details'>View </Link>
                    </div>
                </div>
                </div>

                <div className='phone-table-item'>
                <div className='table-item-left'>
                        <h2> 2 </h2>
                </div>
                <div className='table-item-right'>
                    <div> 
                        <h2>Jatin </h2> 
                        <h2>+91 9999999999 </h2>   
                    </div>
                    <div>
                        <p> jatin@gmail.com </p>
                    </div>
                    <div>
                        <p> 21-09-2024 </p>
                        <Link to=''>View </Link>
                    </div>
                </div>
                </div>

                <div className='phone-table-item'>
                <div className='table-item-left'>
                        <h2> 3 </h2>
                </div>
                <div className='table-item-right'>
                    <div> 
                        <h2>Jatin </h2> 
                        <h2>+91 9999999999 </h2>   
                    </div>
                    <div>
                        <p> jatin@gmail.com </p>
                    </div>
                    <div>
                        <p> 21-09-2024 </p>
                        <Link to=''>View </Link>
                    </div>
                </div>
                </div>


                <div className='phone-table-item'>
                <div className='table-item-left'>
                        <h2> 4 </h2>
                </div>
                <div className='table-item-right'>
                    <div> 
                        <h2>Jatin </h2> 
                        <h2>+91 9999999999 </h2>   
                    </div>
                    <div>
                        <p> jatin@gmail.com </p>
                    </div>
                    <div>
                        <p> 21-09-2024 </p>
                        <Link to=''>View </Link>
                    </div>
                </div>
                </div>


                <div className='phone-table-item'>
                <div className='table-item-left'>
                        <h2> 5 </h2>
                </div>
                <div className='table-item-right'>
                    <div> 
                        <h2>Jatin </h2> 
                        <h2>+91 9999999999 </h2>   
                    </div>
                    <div>
                        <p> jatin@gmail.com </p>
                    </div>
                    <div>
                        <p> 21-09-2024 </p>
                        <Link to=''>View </Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default PendingFollowUps;