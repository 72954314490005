import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Brand from './img/brand.svg';


const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            // console.log(userData);
            navigate("/");
        }
    }, []);
    const Login = (e) => {
        e.preventDefault();
        // alert(username+password);
        var info;
        if (username == "7669845546") {
            info = { "username": username, "designation": "1" };
            localStorage.setItem('user', JSON.stringify(info));
        }
        else if(username == "9999999999"){
            info = { "username": username, "designation": "2" };
            localStorage.setItem('user', JSON.stringify(info));

        }else{

        }

        navigate("/");
    }

    return (
        <>
            <div className="login-page">
                <img className='' src={Brand} alt="swarnbhoomii-logo" />

                <div className="login-card">
                    <h2> CRM    </h2>
                    <div>
                        <h3> Log In </h3>

                        <form onSubmit={(e) => Login(e)}>
                            <label>Username</label>
                            <br />
                            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                            <br />
                            <label> Password </label>
                            <br />
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <br />

                            <button className=""> Log In</button>
                            <Link to="/">reset password ?</Link>
                        </form>

                    </div>
                </div>
            </div>

        </>
    );
}

export default Login;
