import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import AddLeadPopup from './components/AddLeadPopup';

const Home = () => {
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);


    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }

    return (
        <>

            {toggleLeadPopup === 1 ?
                <AddLeadPopup close={closeLeadPopup} />
                : <></>
            }
            <Header />

            <div className="crm-container">
                <div className='dashboard-cards'>

                    <div className='crm-card'>
                        <Link to='/leads' className='crm-bg-green'>
                            <i className='fa fa-users'></i>
                        </Link>
                        <h2 className='crm-color-green'>My Leads</h2>
                    </div>

                    <div className='crm-card'>
                        <Link to='' className='crm-bg-pink'>
                            <i className='fa fa-circle-o-notch'></i>
                        </Link>
                        <h2 className='crm-color-pink'>Follow Up</h2>
                    </div>

                    <div className='crm-card'>
                        <Link onClick={()=>setToggleLeadPopup(1)} className='crm-bg-primary'>
                            <div>
                                <i className='fa fa-user-plus'></i>
                            </div>
                        </Link>
                        <h2 className='crm-color-primary'>Add New Leads</h2>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Home;