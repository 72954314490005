import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Leads from "./Leads";
import ViewLeadDetails from "./ViewLeadDetails";
import Login from "./Login";
import PendingFollowUps from "./PendingFollowUps";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} exact/>
        <Route path="/leads" element={<Leads/>} exact/>
        <Route path="/pending-follow-ups" element={<PendingFollowUps/>} exact/>
        <Route path="/view-lead-details" element={<ViewLeadDetails/>} exact/>
        <Route path="/login" element={<Login/>} exact/>
      </Routes>
    </BrowserRouter>  
  );
}

export default App;
