import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import EditLeadDetailsPopup from './components/EditLeadDetailsPopup';
import AddResponsePopup from './components/AddResponsePopup';

const ViewLeadDetails = () => {

    const [toggleEditLeadPopup, setToggleEditLeadPopup] = useState(0);
    const [toggleResponsePopup, setToggleResponsePopup] = useState(0);
    const [designation, setDesignation] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setDesignation(userData.designation);
            // console.log(designation);
        }
        else {
            navigate("/login");
        }
    }, []);


    const closeEditLeadPopup = () => {
        setToggleEditLeadPopup(0);
    }

    const closeAddResponsePopup = () => {
        setToggleResponsePopup(0);
    }

    return (
        <>
            <Header />

            {toggleEditLeadPopup === 1 ?
                <EditLeadDetailsPopup close={closeEditLeadPopup} />
                : <></>
            }

            {toggleResponsePopup === 1 ?
                <AddResponsePopup close={closeAddResponsePopup} />
                : <></>
            }

            <div className='container-head'>
                <div>
                    <h2> Jatin </h2>
                </div>

                <div>

                    {designation == 1 ? <button onClick={() => { setToggleResponsePopup(1) }} className='crm-btn-primary'> Add Response <i className='fa fa-reply'></i> </button>
                        : <></>}
                </div>

            </div>
            <div className='crm-container'>

                <div className='view-lead-page'>
                    <div className='cards-flex'>
                        <div className='lead-details-card'>
                            <div className='table-item-left'>
                                <h2> 1 </h2>
                            </div>
                            <div className='table-item-right'>
                                <div>
                                    <h2>Jatin </h2>
                                    <h2>+91 9999999999 </h2>
                                </div>
                                <div>
                                    <p> jatin@gmail.com </p>
                                </div>
                                <div>
                                    <p> 21-09-2024 </p>
                                </div>
                                <div className='lead-details-card-right'>
                                    <h3> Visit On: 27-Sep-2024</h3>
                                </div>
                            </div>
                        </div>

                        <div className='lead-details-card'>
                            <div className='div'>
                                <div className='lead-details-card-right'>
                                    <h2> Location :</h2> <p> Tronica City</p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Site :</h2> <p> Anmol Vihar </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Property Type :</h2> <p> Plot </p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Client Category :</h2> <p> Investor</p>
                                </div>

                                <div className='lead-details-card-right'>
                                    <h2> Budget ₹ :</h2> <p> 20 Lakh</p>
                                </div>

                                {designation == 1 ? <div className='lead-details-card-right'>
                                    <h2> </h2> <i onClick={() => setToggleEditLeadPopup(1)} className='fa fa-edit edit'></i>
                                </div>
                                    : <></>}


                            </div>
                        </div>
                    </div>

                    <div className='follow-up-section'>
                        <h2 className='h2'> <i className='fa fa-headphones'></i> Follow Up</h2>

                        <div>
                            <div className='follow-up-card'>
                                <div>
                                    <h2> <i className='fa fa-clock-o'></i> 20-Sep-2024 | 01:33 PM </h2>
                                    <h2> Next Follow Up : 21-Sep-2024 | 01:33 PM </h2>
                                </div>

                                <div className='lead-response'>
                                    <p> Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh </p>

                                    <h3 className='crm-color-primary'> Follow Up</h3>
                                </div>
                            </div>

                            <div className='follow-up-card'>
                                <div>
                                    <h2> <i className='fa fa-clock-o'></i> 21-Sep-2024 | 01:33 PM </h2>
                                    <h2> Next Follow Up : 23-Sep-2024 | 01:33 PM </h2>
                                </div>

                                <div className='lead-response'>
                                    <p> Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh </p>

                                    <h3 className='crm-color-primary'> Follow Up</h3>
                                </div>
                            </div>

                            <div className='follow-up-card'>
                                <div>
                                    <h2> <i className='fa fa-clock-o'></i> 23-Sep-2024 | 01:33 PM </h2>
                                    <h2> Next Follow Up : 24-Sep-2024 | 01:33 PM </h2>
                                </div>

                                <div className='lead-response'>
                                    <p> Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh Call ayi tothi Tronica City ke liye but door h wha se, Dwarka mor ke pass, Gopal Nagar ke
                                        pass hoga kuch to dekh </p>

                                    <h3 className='crm-color-green'> Visit On 25 Sep 2024</h3>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>


    );
}

export default ViewLeadDetails;