import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Brand from './../img/brand.svg'
import AddLeadPopup from './../components/AddLeadPopup';

const Header = () => {

    const [state, setState] = useState(1);
    const [nav, setNav] = useState("fa fa-bars");
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);


    const [designation, setDesignation] = useState();

    const location = useLocation();

    const navigate = useNavigate();

    // console.log(location.pathname);


    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setDesignation(userData.designation);
            // console.log(designation);
        }
        else {
            navigate("/login");
        }

        // localStorage.clear();
    }, []);

    const drawer = (status) => {
        document.querySelector('.sidebar').style.left = status === 1 ? 'calc(100% - 250px)' : 'calc(100% - 45px)';
        // document.querySelector('.header-right i.fa-bars').style.display = status === 1? 'none' : 'block';
        // document.querySelector('.header-right i.fa-power-off').style.display = status === 1? 'block' : 'none';
        if (state === 1) {
            setState(0);
            setNav("fa fa-times");
        }
        else if (state === 0) {
            setState(1);
            setNav("fa fa-bars");
        }
    }

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }


    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }

    return (
        <>

            {toggleLeadPopup === 1 ?
                <AddLeadPopup close={closeLeadPopup} />
                : <></>
            }
            <div className='header'>
                <h2 className='crm'> CRM </h2>

                <div className='header-right'>
                    <h2 className='designation'> Admin/Dipesh </h2>
                    <i className='fa fa-power-off visible-hide logout' onClick={()=>logout()}></i>
                    <i className={nav + ' hide-visible'} onClick={() => { drawer(state) }}></i>
                </div>
            </div>
            <div className='sidebar'>
                <img className='brand visible-hide' src={Brand} alt="swarnbhoomii-logo" />

                <ul>
                    <li className={location.pathname === '/' ? 'side-link active' : 'side-link'}> <Link to='/' > <i className='fa fa-tachometer'></i>Dashboard </Link></li>
                    {designation == 2 || designation == 1?  <li className={location.pathname === '/leads' ? 'side-link active' : 'side-link'}> <Link to='/leads'> <i className='fa fa-users'></i>Leads </Link></li>
                        : <></>}
                    {designation == 2 ? <li className='side-link'> <button onClick={() => setToggleLeadPopup(1)}> <i className='fa fa-user-plus'></i> Add Lead </button></li>
                        : <></>}
                    {designation == 1 ? <li className={location.pathname === '/pending-follow-ups' ? 'side-link active' : 'side-link'}> <Link to='/pending-follow-ups'> <i className='fa fa-users'></i>Pend. Follow Ups </Link></li>
                        : <></>}
                    {designation == 1 ? <li className={location.pathname === '/digital-marketers' ? 'side-link active' : 'side-link'}> <Link to='/'> <i className='fa fa-user'></i>Digital Marketers </Link></li>
                        : <></>}
                    {designation == 1 ? <li className={location.pathname === '/telecallers' ? 'side-link active' : 'side-link'}> <Link to='/'> <i className='fa fa-headphones'></i>Telecallers </Link></li>
                        : <></>}
                    {designation == 1 ? <li className={location.pathname === '/Leads Managers' ? 'side-link active' : 'side-link'}> <Link to='/'> <i className='fa fa-black-tie'></i>Leads Managers </Link></li>
                        : <></>}
                    {designation == 1 ? <li className={location.pathname === '/Sales Team' ? 'side-link active' : 'side-link'}> <Link to='/'> <i className='fa fa-tag'></i>Sales Team </Link></li>
                        : <></>}
                    {designation == 1 ? <li className={location.pathname === '/settings' ? 'side-link active' : 'side-link'}> <Link to='/'> <i className='fa fa-gear'></i> Settings </Link></li>
                        : <></>}
                    <li className='side-link'> <button onClick={() => logout()}> <i className='fa fa-power-off'></i>Log Out </button></li>
                </ul>
            </div>
        </>
    );
}

export default Header;